











import Vue from 'vue';
export default Vue.extend({
  name: 'Toggle',
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    isDark: { type: Boolean, default: false },
  },
  watch: {
    value() {
      this.active = this.value;
    },
  },
  data() {
    return {
      active: this.value,
    };
  },
});
