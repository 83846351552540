































































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Button from '@/components/Button/Button.vue';
import EditButton from '@/components/Button/CircleButton.vue';
import DelivererForm from '@/components/OrganizationalMap/Deliverer/DelivererForm.vue';
import Table from '@/components/Table/Table.vue';
import DeleteDeliverer from '@/components/OrganizationalMap/Deliverer/DeleteDeliverer.vue';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import Deliverer from '@/model/deliverer';

export default Vue.extend({
  name: 'DelivererList',
  components: {
    DetailedViewBase,
    SearchBar,
    Button,
    EditButton,
    DelivererForm,
    Table,
    DeleteDeliverer,
  },
  data() {
    return {
      loading: false,
      opened: false,
      plus: '/images/plus.svg',
      searchText: '',
      pageOptions: new PageOptions(),
      hoveredItem: null as Deliverer | null,
      tableFields: [
        {
          text: this.$t('organizational-map.deliverers.form.name'),
          key: 'name',
          label: this.$t('organizational-map.deliverers.form.name'),
          thStyle: 'width: 40%',
        },
        {
          text: this.$t('organizational-map.deliverers.form.activity'),
          key: 'activity',
          label: this.$t('organizational-map.deliverers.form.activity'),
          thStyle: 'width: 25%',
        },
        {
          text: this.$t('organizational-map.deliverers.form.comment'),
          key: 'comment',
          label: this.$t('organizational-map.deliverers.form.comment'),
          thStyle: 'width: 25%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 10%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ deliverers: 'delivererStorage/getPageableDeliverers' }),
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('delivererStorage/getAllDelivererPage', {
          text: this.searchText,
          pageOptions: this.pageOptions,
        })
        .finally(() => (this.loading = false));
    },
    open() {
      this.fetch();
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    textFiltering(searchText: string) {
      this.searchText = searchText;
      this.fetch();
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    setHover(item: Deliverer | null) {
      this.hoveredItem = item;
    },
    isHovered(item: Deliverer): boolean {
      return item?.id === this.hoveredItem?.id;
    },
  },
});
