














































import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import OperationalUnitBox from '@/components/OrganizationalMap/OperationalUnitBox.vue';
import OperationalUnitDetailsForm from '@/components/BasicInformation/OperationalUnits/OperationalUnitDetailsForm.vue';
import Button from '@/components/Button/Button.vue';
import DelivererList from '@/components/OrganizationalMap/Deliverer/DelivererList.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OrganizationalMap',
  components: {
    TopMenu,
    LoadingIndicator,
    OperationalUnitBox,
    OperationalUnitDetailsForm,
    Button,
    DelivererList,
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('operationalUnitStorage/getOperationalUnits').finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({
      operationalUnits: 'operationalUnitStorage/getOperationalUnits',
    }),
  },
  data() {
    return {
      loading: false,
      plus: '/images/plus.svg',
      delivererIcon: '/images/deliverer.svg',
    };
  },
});
