














































import Vue from 'vue';
import Checkbox from '@/components/InputField/Checkbox.vue';

export default Vue.extend({
  name: 'AutoComplete',
  components: {
    Checkbox,
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    items: { type: Array as () => any[] },
    selected: { type: [Number, Object as () => any, Array as () => any[]] },
    multiple: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    filterMethod: { type: Function },
    borderless: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
  },
  data() {
    return {
      selectedItem: this.selected ?? null,
      focused: false,
    };
  },
  watch: {
    selected() {
      this.selectedItem = this.selected ?? null;
    },
  },
  methods: {
    change(selected: number | null) {
      this.$emit('change', selected);
    },
    changeItem(selected: number) {
      this.$nextTick(() => {
        this.selectedItem.length
          ? this.selectedItem?.includes(selected)
            ? (this as any).removeItem(this.selectedItem, selected)
            : this.selectedItem.push(selected)
          : (this.selectedItem = [selected]);
        this.resetSearch();
        this.$emit('change', this.selectedItem);
      });
    },
    clear() {
      this.$nextTick(() => {
        this.selectedItem = null;
        this.resetSearch();
      });
    },
    displayChosen() {
      this.focused = false;
      this.$emit('focusout');
    },
    blur() {
      (this.$refs.autocomplete as any).blur();
    },
    focus() {
      (this.$refs.autocomplete as any).focus();
      (this.$refs.autocomplete as any).activateMenu();
    },
    resetSearch() {
      (this.$refs.autocomplete as any).lazySearch = '';
    },
  },
});
