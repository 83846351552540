












































import Vue from 'vue';
import SelectedFile from '@/components/Dashboard/RequirementAddition/SelectedFile.vue';
import Message from '@/model/message';
import Attachment from '@/../public/images/message-attachment.svg?inline';

export default Vue.extend({
  name: 'OpenedMessage',
  components: {
    Attachment,
    SelectedFile,
  },
  props: {
    message: { type: Object as () => Message },
  },
  data() {
    return {
      doneIcon: '/images/done.svg',
      notDoneIcon: '/images/not-done.svg',
    };
  },
});
