



























import Vue from 'vue';
export default Vue.extend({
  name: 'Select',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    items: { type: Array as () => any[] },
    value: { type: [String, Object as () => any] },
    errorMessage: { type: String, default: '' },
    extraClass: { type: String, default: '' },
    outlined: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  methods: {
    selectItem(newValue: any) {
      this.selected = newValue;
      this.$emit('change', this.selected);
      (this.$refs.select as any).blur();
    },
  },
});
