










































































import Vue from 'vue';
import Button from '@/components/DetailedViewPopup/ActionButton.vue';
import CircleButton from '@/components/Button/CircleButton.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Document from '@/model/document';
import Notification from '@/model/notification';
import { mapGetters } from 'vuex';
import { uploadDocument } from '@/helper/methods';

export default Vue.extend({
  name: 'LayoutDialog',
  components: {
    Button,
    CircleButton,
    LoadingIndicator,
  },
  props: {
    operationalUnitId: { type: Number },
  },
  data() {
    return {
      loading: false,
      opened: false,
      deleting: false,
    };
  },
  computed: {
    ...mapGetters({ layouts: 'documentStorage/getLayoutDocuments' }),
  },
  methods: {
    isImage(layout: Document): boolean {
      return layout.file.type.startsWith('image/');
    },
    isVideo(layout: Document): boolean {
      return layout.file.type.startsWith('video/');
    },
    isPdf(layout: Document): boolean {
      return layout.file.type === 'application/pdf';
    },
    open() {
      this.loading = true;
      this.opened = true;
      this.$store
        .dispatch('documentStorage/getLayoutDocumentsPerOperationalUnit', this.operationalUnitId)
        .finally(() => (this.loading = false));
    },
    close() {
      this.opened = false;
    },
    upload(files: FileList, event: any) {
      let hasNotSupported = false;
      const addedFiles = [] as File[];
      [...files].forEach((file) => {
        if (
          file.type.startsWith('image/') ||
          file.type.startsWith('video/') ||
          file.type === 'application/pdf' ||
          file.type === ''
        ) {
          addedFiles.push(file);
        } else {
          hasNotSupported = true;
        }
      });
      if (addedFiles.length > 0) {
        const formData = uploadDocument(addedFiles);
        this.$store.dispatch('documentStorage/uploadLayoutDocuments', {
          formData,
          operationalUnitId: this.operationalUnitId,
        });
      }
      if (hasNotSupported) {
        this.$store.dispatch('showErrorNotification', {
          message: this.$t('basic-information.operational-units.layouts.not-supported-file'),
        });
      }
      event.target.value = '';
    },
    removeLayoutFile(layout: Document) {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(this.$t('basic-information.operational-units.layouts.delete-confirmation').toString()),
        )
        .then((confirmed: boolean) => {
          if (confirmed) {
            this.deleting = true;
            this.$store
              .dispatch('documentStorage/removeLayoutDocument', {
                layoutId: layout.id,
                operationalUnitId: this.operationalUnitId,
              })
              .finally(() => (this.deleting = false));
          }
        });
    },
  },
});
