import Document from '@/model/document';
import { PlaceTrait } from '@/model/enums/place-trait';
import { BusinessLegalStatus } from '@/model/enums/business-legal-status';
import { OperationalUnitType } from '@/model/enums/operational-unit-type';

export default class OperationalUnitRequest {
  public mainUnitId: number | null;
  public identifier: string;
  public name: string;
  public address: string;
  public active: boolean;
  public management: string;
  public owner: string;
  public mainActivity: string;
  public numberOfEmployees: number;
  public hasGovernanceSystem: boolean;
  public organizationUnit: string;
  public locationCode: string;
  public dangerousActivities: boolean;
  public type: OperationalUnitType;
  public placeTrait: PlaceTrait;
  public legalStatus: BusinessLegalStatus;
  public comment: string;
  public otherInformation: string;
  public documents: Document[];
  public id?: number;

  constructor(
    mainUnitId: number | null,
    identifier: string,
    name: string,
    address: string,
    active: boolean,
    management: string,
    owner: string,
    mainActivity: string,
    numberOfEmployees: number,
    hasGovernanceSystem: boolean,
    organizationUnit: string,
    locationCode: string,
    dangerousActivities: boolean,
    type: OperationalUnitType,
    placeTrait: PlaceTrait,
    legalStatus: BusinessLegalStatus,
    comment: string,
    otherInformation: string,
    documents: Document[],
    id?: number,
  ) {
    this.mainUnitId = mainUnitId;
    this.id = id;
    this.identifier = identifier;
    this.name = name;
    this.address = address;
    this.active = active;
    this.management = management;
    this.owner = owner;
    this.mainActivity = mainActivity;
    this.numberOfEmployees = numberOfEmployees;
    this.hasGovernanceSystem = hasGovernanceSystem;
    this.organizationUnit = organizationUnit;
    this.locationCode = locationCode;
    this.dangerousActivities = dangerousActivities;
    this.type = type;
    this.placeTrait = placeTrait;
    this.legalStatus = legalStatus;
    this.comment = comment;
    this.otherInformation = otherInformation;
    this.documents = documents;
  }
}
