






























import Vue from 'vue';
import Textarea from '@/components/InputField/Textarea.vue';
import FileInput from '@/components/InputField/FileInput.vue';
import SelectedFile from '@/components/Dashboard/RequirementAddition/SelectedFile.vue';
import Notification from '@/model/notification';
import { maxLength } from 'vuelidate/lib/validators';
import { MessageRequest } from '@/model/request/message-request';

export default Vue.extend({
  name: 'MessageForm',
  components: {
    Textarea,
    FileInput,
    SelectedFile,
  },
  props: {
    saving: { type: Boolean, default: false },
    unitId: { type: Number },
  },
  data() {
    return {
      text: '',
      attachments: new Array<File>(),
      submitted: false,
    };
  },
  validations: {
    text: {
      maxLength: maxLength(10000),
    },
  },
  computed: {
    textError(): string {
      return this.submitted && !this.$v.text.maxLength
        ? this.$t('basic-information.message.form.txt-max-length').toString()
        : '';
    },
  },
  methods: {
    onAttachmentModification(attachments: File[]) {
      this.attachments = this.attachments.concat(attachments);
    },
    onFileRemove(removedFile: File) {
      if (removedFile) {
        this.attachments = this.attachments.filter((file: File) => file.name !== removedFile.name);
      }
    },
    save() {
      this.submitted = true;
      this.$v.text.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (this.text.length <= 0 && this.attachments.length <= 0) {
        this.$store
          .dispatch(
            'showConfirmNotification',
            new Notification(this.$t('basic-information.message.form.sure-to-save-empty').toString()),
          )
          .then((confirmed) => {
            if (confirmed) {
              this.sendRequest();
            }
          });
      } else {
        this.sendRequest();
      }
    },
    sendRequest() {
      const formData = new FormData();
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });

      const messageData: MessageRequest = {
        unitId: this.unitId,
        text: this.text,
      };

      formData.append(
        'message',
        new Blob([JSON.stringify(messageData)], {
          type: 'application/json',
        }),
      );
      this.$emit('setSaving', true);
      this.$store.dispatch('messageStorage/save', { formData, unitId: this.unitId }).finally(() => {
        this.$emit('setSaving', false);
        this.$emit('back');
      });
    },
  },
});
