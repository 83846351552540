import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('organizational-map.deliverers.form.add'),
    componentName: 'OperationalUnitDelivererAddition',
  },
  {
    name: i18n.t('organizational-map.deliverer'),
    componentName: 'OperationalUnitDeliverers',
  },
];
