import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('basic-information.operational-units.base-details'),
    icon: '/images/info.svg',
    componentName: 'OperationalUnitDetails',
  },
  {
    name: i18n.t('basic-information.operational-units.attachments'),
    icon: '/images/attachments.svg',
    componentName: 'OperationalUnitDocuments',
  },
];

