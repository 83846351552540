

























import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Message from '@/model/message';
import Notification from '@/model/notification';

export default Vue.extend({
  name: 'MessageRow',
  components: {
    LoadingIndicator,
    Checkbox,
  },
  props: {
    message: { type: Object as () => Message },
  },
  data() {
    return {
      attachmentIcon: '/images/message-attachment.svg',
      doneIcon: '/images/done.svg',
      notDoneIcon: '/images/not-done.svg',
      loading: false,
    };
  },
  computed: {
    shortenedText(): string {
      return this.message.text.length > 42 ? `${this.message.text.substring(0, 40)}...` : this.message.text;
    },
  },
  methods: {
    setConfirmed() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              this.message.confirmed
                ? 'basic-information.message.sure-to-withdraw-confirmation'
                : 'basic-information.message.sure-to-confirm',
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store.dispatch('messageStorage/confirm', this.message.id).finally(() => (this.loading = false));
          }
        });
    },
  },
});
