





import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Document from '@/model/document';
import Notification from '@/model/notification';

export default Vue.extend({
  name: 'DeleteDocument',
  components: {
    LoadingIndicator,
  },
  props: {
    document: { type: Object as () => Document },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteDocument() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.delete-document', {
              title: this.document.file.originalName,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$emit('delete');
          }
        });
    },
    resetLoading() {
      this.loading = false;
    },
  },
});
