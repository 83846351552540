
















import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import EditIcon from '@/../public/images/edit.svg?inline';
import DeleteIcon from '@/../public/images/delete.svg?inline';

export default Vue.extend({
  name: 'CircleButton',
  components: {
    LoadingIndicator,
    EditIcon,
    DeleteIcon,
  },
  props: {
    isLarge: { type: Boolean, default: false },
    iconType: {
      validator: (value: string) => ['add', 'delete', 'info', 'edit', 'restore', 'download'].includes(value),
      required: true,
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    icon(): string {
      let icon = '';
      switch (this.iconType) {
        case 'add':
          icon = 'fas fa-plus';
          break;
        case 'info':
          icon = 'fas fa-info';
          break;
        case 'restore':
          icon = 'fas fa-undo';
          break;
        case 'download':
          icon = 'fas fa-download';
          break;
      }
      return icon;
    },
    isEdit(): boolean {
      return this.iconType === 'edit';
    },
    isDelete(): boolean {
      return this.iconType === 'delete';
    },
  },
});
