

































import Vue from 'vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import OperationalUnitDetails from '@/components/BasicInformation/OperationalUnits/Details.vue';
import OperationalUnitDocuments from '@/components/BasicInformation/OperationalUnits/Documents.vue';
import DeleteOperationalUnit from '@/components/BasicInformation/OperationalUnits/DeleteOperationalUnit.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import OperationalUnit from '@/model/operational-unit';
import { menus } from '@/constants/menu/operational-unit-menus';

export default Vue.extend({
  name: 'OperationalUnitDetailedView',
  components: {
    DetailedViewBase,
    ActionButton,
    DeleteOperationalUnit,
    OperationalUnitDetails,
    OperationalUnitDocuments,
    LoadingIndicator,
    Navigation,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
    mainUnitId: { type: Number, default: null },
  },
  data() {
    return {
      menus,
      opened: false,
      editing: false,
      saving: false,
      activeComponent: 'OperationalUnitDetails',
    };
  },
  computed: {
    isDetailsPage(): boolean {
      return this.activeComponent === 'OperationalUnitDetails';
    },
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.resetEdition();
    },
    edit() {
      (this.$refs.details as any).reset();
      this.editing = true;
    },
    save() {
      (this.$refs.details as any).save();
    },
    resetEdition() {
      this.editing = false;
      if (this.isDetailsPage) {
        (this.$refs.details as any).reset();
      }
    },
    setSaving(value: boolean) {
      this.saving = value;
    },
    onDelete() {
      this.close();
      this.$emit('selectFirst');
    },
  },
});
