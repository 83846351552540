



















import Vue from 'vue';

export default Vue.extend({
  name: 'TextField',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    type: { validator: (value: string) => ['text', 'number', 'email', 'password'].includes(value) },
    value: { type: [String, Number] },
    minValue: { type: Number, default: 0 },
    errorMessage: { type: String, default: '' },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    detailsHidden: { type: Boolean, default: false },
    isArea: { type: Boolean, default: false },
  },
  watch: {
    value() {
      this.text = this.value;
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
  methods: {
    input(value: string | number) {
      this.$nextTick(() => {
        this.text =
          this.type === 'number'
            ? !value || value < this.minValue
              ? this.minValue
              : parseInt(value.toString(), 10)
            : value;
        this.$emit('input', this.text);
      });
    },
  },
});
