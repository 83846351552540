var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{on:{"click":_vm.open}},[_vm._t("button")],2),_c('DetailedViewBase',{attrs:{"opened":_vm.opened},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"detailed-title"},[_vm._v(_vm._s(_vm.$t('organizational-map.deliverer')))])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"basic-info-detailed-view-content"},[_c('div',{staticClass:"d-flex row m-0"},[_c('SearchBar',{ref:"textFilter",staticClass:"search m-0",attrs:{"hasPrependIcon":""},on:{"filter":_vm.textFiltering}}),_c('div',{staticClass:"add-button-container"},[(_vm.canAddDeliverers)?_c('DelivererForm',{attrs:{"searchText":_vm.searchText},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Button',{attrs:{"image":_vm.plus,"text":_vm.$t('organizational-map.deliverers.form.add'),"isLarge":""}})]},proxy:true}],null,false,3064608845)}):_vm._e()],1)],1),_c('Table',{staticClass:"mt-4 deliverer-table",attrs:{"data":_vm.deliverers,"fields":_vm.tableFields,"formattedColumns":['name', 'activity', 'comment', 'buttons'],"hasHeader":false,"isLoading":_vm.loading,"isDeliverer":"","isInfiniteScroll":"","stickyHeader":"27.5rem"},on:{"newPageOption":_vm.pageOptionsChanged,"hover":function($event){return _vm.setHover.apply(void 0, arguments)},"unhover":_vm.setHover},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.name)+" ")]}},{key:"activity",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.activity)+" ")]}},{key:"comment",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.comment)+" ")]}},{key:"buttons",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"button-wrapper"},[(_vm.canAddDeliverers)?_c('div',{staticClass:"d-flex row m-0 align-center"},[_c('DelivererForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered(rowData)),expression:"isHovered(rowData)"}],staticClass:"pr-3",attrs:{"searchText":_vm.searchText,"deliverer":rowData},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('EditButton',{attrs:{"iconType":"edit","disabled":_vm.loading}})]},proxy:true}],null,true)}),_c('DeleteDeliverer',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHovered(rowData)),expression:"isHovered(rowData)"}],attrs:{"deliverer":rowData}})],1):_vm._e()])]}}])})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }