







































import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Table from '@/components/Table/Table.vue';
import RemoveDeliverer from '@/components/OrganizationalMap/Deliverer/RemoveOperationalUnitDeliverer.vue';
import EditButton from '@/components/Button/CircleButton.vue';
import DelivererForm from '@/components/OrganizationalMap/Deliverer/DelivererForm.vue';
import Deliverer from '@/model/deliverer';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import OperationalUnit from '@/model/operational-unit';

export default Vue.extend({
  name: 'OperationalUnitDeliverers',
  components: {
    SearchBar,
    Table,
    RemoveDeliverer,
    EditButton,
    DelivererForm,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
  },
  data() {
    return {
      loading: false,
      searchText: '',
      pageOptions: new PageOptions(),
      hoveredItem: null as Deliverer | null,
      tableFields: [
        {
          text: this.$t('organizational-map.deliverers.form.name'),
          key: 'name',
          label: this.$t('organizational-map.deliverers.form.name'),
          thStyle: 'width: 72.5%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 27.5%',
        },
      ],
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({ deliverers: 'delivererStorage/getOperationalUnitDeliverers' }),
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('delivererStorage/getOperationalUnitDelivererPage', {
          operationalUnitId: this.operationalUnit.id,
          text: this.searchText,
          pageOptions: this.pageOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.searchText = searchText;
      this.fetch();
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    setHover(item: Deliverer | null) {
      this.hoveredItem = item;
    },
    isHovered(item: Deliverer): boolean {
      return item?.id === this.hoveredItem?.id;
    },
  },
});
