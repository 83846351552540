

















import Document from '@/model/document';
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectedFile',
  model: {
    prop: 'attachmentList',
    event: 'remove',
  },
  props: {
    attachmentList: { type: [Array as () => File[], Array as () => Document[]] },
    savedDocument: { type: Object as () => Document, default: null },
    file: { type: File },
    loading: { type: Boolean, default: false },
    isSizeDisplayed: { type: Boolean, default: true },
    removable: { type: Boolean, default: true },
  },
  watch: {
    attachmentList() {
      this.attachments = this.attachmentList;
    },
  },
  data() {
    return {
      close: '/images/close.svg',
      attachments: this.attachmentList,
    };
  },
  computed: {
    fileName(): string {
      return (this as any).shortenFileName(
        this.savedDocument ? this.savedDocument.file.originalName : this.file.name,
        25,
      );
    },
    fileSize(): number {
      return this.savedDocument ? this.savedDocument.file.size : this.file.size;
    },
  },
  methods: {
    removeFile() {
      if (!this.loading) {
        const index = this.savedDocument
          ? this.attachments.findIndex((document: any) => document.id === this.savedDocument.id)
          : this.attachments.findIndex((file: any) => file.name === this.file.name);
        if (index >= 0) {
          this.attachments.splice(index, 1);
        }
        this.$emit('removeFile', this.attachments);
      }
    },
  },
});
