
































































































































































































































































import Vue from 'vue';
import { required, maxLength, minValue } from 'vuelidate/lib/validators';
import FormBase from '@/components/Form/Form.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import TextField from '@/components/InputField/TextField.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import FileUpload from '@/components/InputField/FileUpload.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Select from '@/components/InputField/Select.vue';
import Document from '@/model/document';
import { OperationalUnitType } from '@/model/enums/operational-unit-type';
import { PlaceTrait } from '@/model/enums/place-trait';
import OperationalUnitRequest from '@/model/request/operational-unit-request';
import Business from '@/model/business';
import Person from '@/model/person';
import Tool from '@/model/tool';
import { BusinessLegalStatus } from '@/model/enums/business-legal-status';

export default Vue.extend({
  name: 'OperationalUnitDetailsForm',
  components: {
    FormBase,
    FormField,
    Textarea,
    TextField,
    Checkbox,
    FileUpload,
    AutoComplete,
    Select,
  },
  props: {
    mainOperationalUnitId: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      submitted: false,
      loading: false,
      places: [] as Business[],
      people: [] as Person[],
      tools: [] as Tool[],
      form: {
        identifier: '',
        name: '',
        address: '',
        active: false,
        management: '',
        owner: '',
        mainActivity: '',
        numberOfEmployees: 0,
        hasGovernanceSystem: false,
        organizationUnit: '',
        locationCode: '',
        dangerousActivities: false,
        type: '' as OperationalUnitType,
        placeTrait: '' as PlaceTrait,
        legalStatus: '' as BusinessLegalStatus,
        comment: '',
        otherInformation: '',
        documents: [] as Document[],
      },
      attachments: new Array<File>(),
      layouts: new Array<File>(),
    };
  },
  validations: {
    form: {
      identifier: {
        required,
        maxLength: maxLength(200),
      },
      name: {
        required,
        maxLength: maxLength(100),
      },
      address: {
        required,
        maxLength: maxLength(1000),
      },
      management: {
        maxLength: maxLength(150),
      },
      owner: {
        maxLength: maxLength(150),
      },
      mainActivity: {
        maxLength: maxLength(500),
      },
      numberOfEmployees: {
        required,
        minValue: minValue(0),
      },
      organizationUnit: {
        maxLength: maxLength(2000),
      },
      locationCode: {
        maxLength: maxLength(1000),
      },
      type: {
        required,
      },
      placeTrait: {
        required,
      },
      legalStatus: {
        required,
      },
      comment: {
        maxLength: maxLength(5000),
      },
      otherInformation: {
        maxLength: maxLength(5000),
      },
    },
  },
  mounted() {
    this.form.type = this.types[0];
    this.form.placeTrait = this.placeTraits[0];
    this.form.legalStatus = this.legalStatuses[0];
  },
  computed: {
    types(): OperationalUnitType[] {
      return Object.keys(OperationalUnitType) as OperationalUnitType[];
    },
    placeTraits(): PlaceTrait[] {
      return Object.keys(PlaceTrait) as PlaceTrait[];
    },
    legalStatuses(): BusinessLegalStatus[] {
      return Object.keys(BusinessLegalStatus) as BusinessLegalStatus[];
    },
    identifierError(): string {
      return this.submitted && !(this.$v.form.identifier as any).required
        ? this.$t('basic-information.operational-units.form.id-required').toString()
        : this.submitted && !(this.$v.form.identifier as any).maxLength
        ? this.$t('basic-information.people.form.inner-id-max-length').toString()
        : '';
    },
    addressError(): string {
      return this.submitted && !(this.$v.form.address as any).required
        ? this.$t('basic-information.operational-units.form.address-required').toString()
        : this.submitted && !(this.$v.form.address as any).maxLength
        ? this.$t('basic-information.operational-units.form.address-max-length').toString()
        : '';
    },
    managementError(): string {
      return this.submitted && !(this.$v.form.management as any).maxLength
        ? this.$t('basic-information.operational-units.form.management-max-length').toString()
        : '';
    },
    ownerError(): string {
      return this.submitted && !(this.$v.form.owner as any).maxLength
        ? this.$t('basic-information.operational-units.form.owner-max-length').toString()
        : '';
    },
    mainActivityError(): string {
      return this.submitted && !(this.$v.form.mainActivity as any).maxLength
        ? this.$t('basic-information.operational-units.form.main-activity-max-length').toString()
        : '';
    },
    numberOfEmployeesError(): string {
      return this.submitted && !(this.$v.form.numberOfEmployees as any).required
        ? this.$t('basic-information.operational-units.form.number-of-employees-required').toString()
        : this.submitted && !(this.$v.form.numberOfEmployees as any).minValue
        ? this.$t('basic-information.operational-units.form.number-of-employees-min').toString()
        : '';
    },
    legalStatusError(): string {
      return this.submitted && !(this.$v.form.legalStatus as any).required
        ? this.$t('basic-information.operational-units.form.legal-status-required').toString()
        : '';
    },
    otherInformationError(): string {
      return this.submitted && !(this.$v.form.otherInformation as any).maxLength
        ? this.$t('basic-information.operational-units.form.other-information-max-length').toString()
        : '';
    },
  },
  methods: {
    resetForm() {
      this.form.identifier = '';
      this.form.name = '';
      this.form.address = '';
      this.form.active = false;
      this.form.management = '';
      this.form.owner = '';
      this.form.mainActivity = '';
      this.form.numberOfEmployees = 0;
      this.form.hasGovernanceSystem = false;
      this.form.organizationUnit = '';
      this.form.locationCode = '';
      this.form.dangerousActivities = false;
      this.form.type = this.types[0];
      this.form.placeTrait = this.placeTraits[0];
      this.form.legalStatus = this.legalStatuses[0];
      this.form.comment = '';
      this.form.otherInformation = '';
      this.form.documents = [] as Document[];
      this.attachments = [] as File[];
      this.submitted = false;
    },
    openDialog() {
      if (!this.disabled) {
        (this.$refs.dialog as any).open();
      }
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const formData = new FormData();
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });
      this.layouts.forEach((file: File) => {
        formData.append('layout', file);
      });
      formData.append(
        'operationalUnit',
        new Blob(
          [
            JSON.stringify(
              new OperationalUnitRequest(
                this.mainOperationalUnitId,
                this.form.identifier,
                this.form.name,
                this.form.address,
                this.form.active,
                this.form.management,
                this.form.owner,
                this.form.mainActivity,
                this.form.numberOfEmployees,
                this.form.hasGovernanceSystem,
                this.form.organizationUnit,
                this.form.locationCode,
                this.form.dangerousActivities,
                this.form.type,
                this.form.placeTrait,
                this.form.legalStatus,
                this.form.comment,
                this.form.otherInformation,
                this.form.documents,
              ),
            ),
          ],
          {
            type: 'application/json',
          },
        ),
      );
      this.loading = true;
      this.$store
        .dispatch('operationalUnitStorage/save', { formData, mainUnitId: this.mainOperationalUnitId })
        .then((data) => {
          if (data) {
            this.$emit('openUnit', data);
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
