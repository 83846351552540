






























































































































































import Vue from 'vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import Select from '@/components/InputField/Select.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import OperationalUnit from '@/model/operational-unit';
import { OperationalUnitBaseDetailsRequest } from '@/model/request/operational-unit-base-details-request';
import { required, maxLength, minValue } from 'vuelidate/lib/validators';
import { BusinessLegalStatus } from '@/model/enums/business-legal-status';

export default Vue.extend({
  name: 'OperationalUnitDetails',
  components: {
    FormField,
    TextField,
    Textarea,
    Select,
    Checkbox,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
    mainUnitId: { type: Number, default: null },
    editing: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
  },
  data() {
    return {
      submitted: false,
      loading: false,
      form: {
        name: this.operationalUnit.name,
        address: this.operationalUnit.address,
        management: this.operationalUnit.management,
        owner: this.operationalUnit.owner,
        numberOfEmployees: this.operationalUnit.numberOfEmployees,
        legalStatus: this.operationalUnit.legalStatus,
        mainActivity: this.operationalUnit.mainActivity,
        hasGovernanceSystem: this.operationalUnit.hasGovernanceSystem,
        otherInformation: this.operationalUnit.otherInformation,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      address: {
        required,
        maxLength: maxLength(1000),
      },
      management: {
        maxLength: maxLength(150),
      },
      owner: {
        maxLength: maxLength(150),
      },
      numberOfEmployees: {
        required,
        minValue: minValue(0),
      },
      legalStatus: {
        required,
      },
      mainActivity: {
        maxLength: maxLength(500),
      },
      otherInformation: {
        maxLength: maxLength(5000),
      },
    },
  },
  computed: {
    legalStatuses(): BusinessLegalStatus[] {
      return Object.keys(BusinessLegalStatus) as BusinessLegalStatus[];
    },
    addressError(): string {
      return this.submitted && !(this.$v.form.address as any).required
        ? this.$t('basic-information.operational-units.form.address-required').toString()
        : this.submitted && !(this.$v.form.address as any).maxLength
        ? this.$t('basic-information.operational-units.form.address-max-length').toString()
        : '';
    },
    managementError(): string {
      return this.submitted && !(this.$v.form.management as any).maxLength
        ? this.$t('basic-information.operational-units.form.management-max-length').toString()
        : '';
    },
    ownerError(): string {
      return this.submitted && !(this.$v.form.owner as any).maxLength
        ? this.$t('basic-information.operational-units.form.owner-max-length').toString()
        : '';
    },
    mainActivityError(): string {
      return this.submitted && !(this.$v.form.mainActivity as any).maxLength
        ? this.$t('basic-information.operational-units.form.main-activity-max-length').toString()
        : '';
    },
    numberOfEmployeesError(): string {
      return this.submitted && !(this.$v.form.numberOfEmployees as any).required
        ? this.$t('basic-information.operational-units.form.number-of-employees-required').toString()
        : this.submitted && !(this.$v.form.numberOfEmployees as any).minValue
        ? this.$t('basic-information.operational-units.form.number-of-employees-min').toString()
        : '';
    },
    legalStatusError(): string {
      return this.submitted && !(this.$v.form.legalStatus as any).required
        ? this.$t('basic-information.operational-units.form.legal-status-required').toString()
        : '';
    },
    otherInformationError(): string {
      return this.submitted && !(this.$v.form.otherInformation as any).maxLength
        ? this.$t('basic-information.operational-units.form.other-information-max-length').toString()
        : '';
    },
  },
  methods: {
    reset() {
      this.submitted = false;
      this.form.name = this.operationalUnit.name;
      this.form.address = this.operationalUnit.address;
      this.form.management = this.operationalUnit.management;
      this.form.owner = this.operationalUnit.owner;
      this.form.numberOfEmployees = this.operationalUnit.numberOfEmployees;
      this.form.legalStatus = this.operationalUnit.legalStatus;
      this.form.mainActivity = this.operationalUnit.mainActivity;
      this.form.hasGovernanceSystem = this.operationalUnit.hasGovernanceSystem;
      this.form.otherInformation = this.operationalUnit.otherInformation;
      this.$emit('setSaving', false);
    },
    save() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('setSaving', true);
      this.$store
        .dispatch('operationalUnitStorage/modifyBaseDetails', {
          request: {
            id: this.operationalUnit?.id,
            ...this.form,
          } as OperationalUnitBaseDetailsRequest,
          mainUnitId: this.mainUnitId,
        })
        .then((data) => {
          if (data) {
            this.$emit('update', data);
          }
        })
        .finally(() => {
          this.$emit('setSaving', false);
          this.submitted = false;
        });
    },
  },
});
