











































import Vue from 'vue';
import Button from '@/components/Button/Button.vue';
import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import MessageForm from '@/components/BasicInformation/Messages/MessageForm.vue';
import MessageList from '@/components/BasicInformation/Messages/MessageList.vue';
import OpenedMessage from '@/components/BasicInformation/Messages/OpenedMessage.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { MessageSiteComponent } from '@/model/enums/message-site-components';
import Message from '@/model/message';

export default Vue.extend({
  props: {
    unitId: {
      type: Number,
    },
  },
  name: 'MessageDialog',
  components: {
    Button,
    DetailedViewBase,
    MessageForm,
    MessageList,
    OpenedMessage,
    LoadingIndicator,
  },
  data() {
    return {
      opened: false,
      loading: false,
      saving: false,
      isNewAddition: false,
      plus: '/images/plus.svg',
      activeComponent: MessageSiteComponent.MESSAGE_LIST,
      openedMessage: {} as Message,
    };
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch('messageStorage/getMessages', this.unitId).finally(() => (this.loading = false));
    },
    open() {
      this.fetch();
      this.activeComponent = MessageSiteComponent.MESSAGE_LIST;
      this.isNewAddition = false;
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    openNewAddition() {
      this.isNewAddition = true;
      this.activeComponent = MessageSiteComponent.NEW_MESSAGE;
    },
    goBack() {
      this.fetch();
      this.isNewAddition = false;
      this.activeComponent = MessageSiteComponent.MESSAGE_LIST;
    },
    sendMessage() {
      if (this.activeComponent === MessageSiteComponent.NEW_MESSAGE) {
        (this.$refs.component as any).save(this.unitId);
      }
    },
    openMessage(message: Message) {
      this.openedMessage = message;
      this.activeComponent = MessageSiteComponent.OPENED_MESSAGE;
      if (!message.seen) {
        this.$store.dispatch('messageStorage/setSeen', { unitId: this.unitId, messageId: message.id });
      }
    },
    setSaving(value: boolean) {
      this.saving = value;
    },
    canShowMessageCreation() {
      return this.activeComponent !== MessageSiteComponent.OPENED_MESSAGE;
    },
  },
});
