























































import Vue from 'vue';
import FormBase from '@/components/Form/Form.vue';
import FormField from '@/components/BasicInformation/FormField.vue';
import TextField from '@/components/InputField/TextField.vue';
import Textarea from '@/components/InputField/Textarea.vue';
import Deliverer from '@/model/deliverer';
import { maxLength, required } from 'vuelidate/lib/validators';
import { DelivererRequest } from '@/model/request/deliverer-request';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';

export default Vue.extend({
  name: 'DelivererForm',
  components: {
    FormBase,
    FormField,
    TextField,
    Textarea,
  },
  props: {
    deliverer: { type: Object as () => Deliverer },
    searchText: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      submitted: false,
      form: {
        name: '',
        activity: '',
        comment: '',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      activity: {
        maxLength: maxLength(500),
      },
      comment: {
        maxLength: maxLength(500),
      },
    },
  },
  computed: {
    activityError(): string {
      return this.submitted && !(this.$v.form.activity as any).maxLength
        ? this.$t('organizational-map.deliverers.form.activity-max-length').toString()
        : '';
    },
  },
  methods: {
    delivererCommentError(validationField: any): string {
      return !validationField.maxLength
        ? this.$t('organizational-map.deliverers.form.comment-max-length').toString()
        : '';
    },
    openDialog() {
      if (this.deliverer) {
        this.setFormFields();
      }
      (this.$refs.dialog as any).open();
    },
    resetForm() {
      this.form.name = '';
      this.form.activity = '';
      this.form.comment = '';
      this.submitted = false;
    },
    setFormFields() {
      this.form.name = this.deliverer.name;
      this.form.activity = this.deliverer.activity;
      this.form.comment = this.deliverer.comment;
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(`delivererStorage/${this.deliverer?.id ? 'modify' : 'save'}`, {
          id: this.deliverer?.id ?? null,
          ...this.form,
          filter: new OperationalUnitFilterRequest(this.searchText),
        } as DelivererRequest)
        .then((data) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
