





























import Vue from 'vue';
import DelivererList from '@/components/OrganizationalMap/Deliverer/DelivererList.vue';
import Button from '@/components/Button/Button.vue';
import OperationalUnitDelivererAddition from '@/components/OrganizationalMap/Deliverer/OperationalUnitDelivererAddition.vue';
import OperationalUnitDeliverers from '@/components/OrganizationalMap/Deliverer/OperationalUnitDeliverers.vue';
import { menus } from '@/constants/menu/deliverer-menus';
import Menu from '@/model/menu';
import OperationalUnit from '@/model/operational-unit';

export default Vue.extend({
  name: 'DelivererMenu',
  components: {
    DelivererList,
    Button,
    OperationalUnitDelivererAddition,
    OperationalUnitDeliverers,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
  },
  data() {
    return {
      menus,
      opened: false,
      activeComponent: 'OperationalUnitDeliverers',
    };
  },
  computed: {
    isAdditionPage(): boolean {
      return this.activeComponent === 'OperationalUnitDelivererAddition';
    },
  },
  methods: {
    setComponent(menu: Menu) {
      this.activeComponent = menu.componentName;
    },
    isActiveComponent(menu: Menu): boolean {
      return menu.componentName === this.activeComponent;
    },
    open() {
      this.opened = true;
      this.$nextTick(() => (this.$refs.component as any).fetch());
    },
  },
});
