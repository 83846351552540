












import Vue from 'vue';
import DelivererIcon from '@/../public/images/deliverer.svg?inline';

export default Vue.extend({
  name: 'DetailsButton',
  components: {
    DelivererIcon,
  },
  props: {
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    opened: { type: Boolean, default: false },
    isDeliverers: { type: Boolean, default: false },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
});
