









import Vue from 'vue';
export default Vue.extend({
  name: 'FormField',
  props: {
    title: { type: String, default: '' },
    isDetail: { type: Boolean, default: false },
  },
});
