















import Vue from 'vue';

export default Vue.extend({
  name: 'Textarea',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: { type: String },
    errorMessage: { type: String, default: '' },
    rows: { type: Number, default: 3 },
    maxLength: { type: Number, default: 10000 },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  watch: {
    value() {
      this.text = this.value;
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
});
