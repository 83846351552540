






















































































































import Vue from 'vue';
import Toggle from '@/components/InputField/Toggle.vue';
import DetailsButton from '@/components/OrganizationalMap/DetailsButton.vue';
import MessageDialog from '@/components/BasicInformation/Messages/MessageDialog.vue';
import DetailedView from '@/components/BasicInformation/OperationalUnits/OperationalUnitDetailedView.vue';
import OperationalUnitDetailsForm from '@/components/BasicInformation/OperationalUnits/OperationalUnitDetailsForm.vue';
import Button from '@/components/Button/Button.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import LayoutDialog from '@/components/OrganizationalMap/LayoutDialog.vue';
import DelivererMenu from '@/components/OrganizationalMap/Deliverer/DelivererMenu.vue';
import { mapGetters } from 'vuex';
import OperationalUnit from '@/model/operational-unit';
import { Route } from '@/model/enums/route';

export default Vue.extend({
  name: 'OperationalUnitBox',
  components: {
    Toggle,
    DetailsButton,
    MessageDialog,
    DetailedView,
    OperationalUnitDetailsForm,
    Button,
    LoadingIndicator,
    LayoutDialog,
    DelivererMenu,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
    mainUnitId: { type: Number, default: null },
    subUnit: { type: Boolean, default: false },
  },
  data() {
    return {
      infoIcon: '/images/base-info.svg',
      layoutIcon: '/images/layout.svg',
      messageIcon: '/images/message.svg',
      add: '/images/add.svg',
      activationChanging: false,
    };
  },
  computed: {
    ...mapGetters({ messageCount: 'messageStorage/getUnseenMessageCount' }),
    routeName(): string {
      return Route.BASIC_INFORMATION;
    },
  },
  methods: {
    openDialog() {
      if (!this.operationalUnit.deactivated) {
        (this.$refs.messages as any).open();
      }
    },
    openBaseDetails() {
      (this.$refs.detailedView as any).open();
    },
    openLayout() {
      (this.$refs.layout as any).open();
    },
    changeDeactivatedStatus(value: boolean) {
      this.activationChanging = true;
      this.$store
        .dispatch('operationalUnitStorage/turnDeactivatedState', {
          id: this.operationalUnit.id,
          deactivated: !value,
        })
        .finally(() => (this.activationChanging = false));
    },
    openMenu() {
      (this.$refs.delivererMenu as any).open();
    },
  },
});
