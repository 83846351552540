















import Vue from 'vue';
import DocumentPageBase from '@/components/BasicInformation/DocumentPageBase.vue';
import OperationalUnit from '@/model/operational-unit';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';

export default Vue.extend({
  name: 'OperationalUnitDocuments',
  components: {
    DocumentPageBase,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
  },
  mounted() {
    this.fetch(this.pageOptions, this.filterOptions);
  },
  data() {
    return {
      uploadImage: '/images/upload-cloud.svg',
      loading: false,
      uploading: false,
      pageOptions: new PageOptions(0, 20),
      filterOptions: new OperationalUnitFilterRequest(''),
    };
  },
  computed: {
    ...mapGetters({ documents: 'documentStorage/getOperationalUnitDocuments' }),
  },
  methods: {
    fetch(pageOptions: PageOptions, filterOptions: OperationalUnitFilterRequest) {
      this.loading = true;
      this.$store
        .dispatch('documentStorage/getOperationalUnitDocuments', {
          id: this.operationalUnit?.id,
          pageOptions,
          filterOptions,
        })
        .finally(() => (this.loading = false));
    },
    onTextFiltering(searchText: string) {
      this.filterOptions.text = searchText;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onPageOptionsChange(): void {
      this.pageOptions.page++;
      this.fetch(this.pageOptions, this.filterOptions);
    },
    onUpload(formData: FormData) {
      this.uploading = true;
      this.$store
        .dispatch('documentStorage/uploadOperationalUnitDocuments', { id: this.operationalUnit?.id, formData })
        .finally(() => (this.uploading = false));
    },
    deleteDocument(deletedDocumentId: number) {
      this.$store
        .dispatch('documentStorage/deleteOperationalUnitDocument', deletedDocumentId)
        .finally(() => (this.$refs.documents as any).resetLoading());
    },
  },
});
