




import Vue from 'vue';
import DeleteButton from '@/components/DetailedViewPopup/ActionButton.vue';
import Notification from '@/model/notification';
import OperationalUnit from '@/model/operational-unit';

export default Vue.extend({
  name: 'DeleteOperationalUnit',
  components: {
    DeleteButton,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
    mainUnitId: { type: Number, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteOperationalUnit() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('basic-information.operational-units.delete', {
              name: this.operationalUnit.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('operationalUnitStorage/delete', {
                operationalUnit: this.operationalUnit,
                mainUnitId: this.mainUnitId,
              })
              .then(() => this.$emit('delete'))
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
