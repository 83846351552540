

























































import Vue, { PropType } from 'vue';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'Table',
  components: {
    LoadingIndicator,
  },
  props: {
    data: { type: Object as () => Pageable<any>, required: true },
    rawData: { type: Array as () => any[], default: () => [] },
    fields: Array as () => object[],
    formattedColumns: Array as PropType<string[]>,
    booleanColumns: Array as PropType<string[]>,
    isLoading: { type: Boolean, default: false },
    isInfiniteScroll: { type: Boolean, default: false },
    isDetail: { type: Boolean, default: false },
    isBasicInformation: { type: Boolean, default: false },
    isDeliverer: { type: Boolean, default: false },
    hasHeader: { type: Boolean, default: true },
    stickyHeader: { type: [Boolean, String], default: false },
  },
  data() {
    return {
      page: 1,
      sortBy: '',
      sortDesc: false,
    };
  },
  computed: {
    isRawData(): boolean {
      return this.rawData && !this.data.items;
    },
  },
  methods: {
    refresh() {
      (this.$refs.table as any).refresh();
    },
    headerSlotName(columnName: string): string {
      return `head(${columnName})`;
    },
    columnSlotName(columnName: string): string {
      return `cell(${columnName})`;
    },
    pageChanged(page: number) {
      this.page = page;
      this.$emit(
        'newPageOption',
        new PageOptions(page > 0 ? page - 1 : 0, (this.data as Pageable<any>).size, this.sortBy, this.sortDesc),
      );
    },
    sortChanged(e: any) {
      this.page = 1;
      this.$emit('newPageOption', new PageOptions(0, e.perPage, e.sortBy, e.sortDesc));
    },
  },
});
