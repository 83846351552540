




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import Deliverer from '@/model/deliverer';

export default Vue.extend({
  name: 'DeleteDeliverer',
  components: {
    DeleteButton,
  },
  props: {
    deliverer: { type: Object as () => Deliverer },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteDeliverer() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('organizational-map.deliverers.delete', {
              name: this.deliverer.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('delivererStorage/delete', this.deliverer)
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
