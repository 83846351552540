











import Vue from 'vue';
import { mapGetters } from 'vuex';
import MessageRow from '@/components/BasicInformation/Messages/MessageRow.vue';
import Message from '@/model/message';

export default Vue.extend({
  props: {
    unitId: { type: Number },
  },
  name: 'MessageList',
  components: {
    MessageRow,
  },
  computed: {
    ...mapGetters({ messages: 'messageStorage/getMessages' }),
  },
  methods: {
    openMessage(message: Message) {
      this.$emit('openMessage', message);
    },
  },
});
