






























import Vue from 'vue';

export default Vue.extend({
  name: 'FileInput',
  props: {
    loading: Boolean,
    needLabel: { type: Boolean, default: true },
    uploading: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    isSmall: { type: Boolean, default: false },
  },
  data() {
    return {
      addedFiles: new Array<File>(),
    };
  },
  methods: {
    upload(files: FileList, event: any) {
      let hasNotSupported = false;
      [...files].forEach((file) => {
        if ((this as any).checkSupportedType(file)) {
          this.addedFiles.push(file);
        } else {
          hasNotSupported = true;
        }
      });
      if (this.addedFiles.length > 0) {
        this.$emit('modifyAttachments', this.addedFiles);
      }
      if (hasNotSupported) {
        this.$store.dispatch('showErrorNotification', {
          message: this.$t('dashboard.requirement-addition.not-supported-file'),
        });
      }
      event.target.value = '';
      this.addedFiles = [];
    },
  },
});
