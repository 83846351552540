





























































import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Table from '@/components/Table/Table.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import Button from '@/components/Button/Button.vue';
import DeleteDocument from '@/components/BasicInformation/DeleteDocument.vue';
import Pageable from '@/model/page/pageable';
import Document from '@/model/document';

export default Vue.extend({
  name: 'DocumentPageBase',
  components: {
    SearchBar,
    Table,
    LoadingIndicator,
    InfiniteScroll,
    Button,
    DeleteDocument,
  },
  props: {
    data: { type: Object as () => Pageable<Document> },
    loading: { type: Boolean, default: false },
    uploading: { type: Boolean, default: false },
    buttonText: { type: String, default: '' },
    buttonIcon: { type: String, default: '' },
    buttonImage: { type: String, default: '' },
  },
  data() {
    return {
      tableFields: [
        {
          text: this.$t('basic-information.operational-units.documents.name'),
          key: 'name',
          label: this.$t('basic-information.operational-units.documents.name'),
          thStyle: 'width: 82.5%',
        },
        {
          text: this.$t('basic-information.operational-units.documents.upload-time'),
          key: 'createdAt',
          label: this.$t('basic-information.operational-units.documents.upload-time'),
          thStyle: 'width: 17.5%',
        },
        {
          key: 'deleteButton',
          label: '',
          thStyle: 'width: 2.5%',
        },
      ],
    };
  },
  methods: {
    textFiltering(searchText: string) {
      this.$emit('textFiltering', searchText);
    },
    pageOptionsChanged(): void {
      this.$emit('pageOptionsChanged');
    },
    upload(files: FileList, event: any) {
      let hasNotSupported = false;
      const filesToUpload = [] as File[];
      [...files].forEach((file) => {
        if ((this as any).checkSupportedType(file)) {
          filesToUpload.push(file);
        } else {
          hasNotSupported = true;
        }
      });
      if (filesToUpload.length > 0) {
        const formData = new FormData();
        filesToUpload.forEach((file: File) => {
          formData.append('file', file);
        });
        this.$emit('upload', formData);
      }
      if (hasNotSupported) {
        this.$store.dispatch('showErrorNotification', {
          message: this.$t('dashboard.requirement-addition.not-supported-file'),
        });
      }
      event.target.value = '';
    },
    resetLoading() {
      if (this.$refs.delete) {
        (this.$refs.delete as any).resetLoading();
      }
    },
  },
});
