





































import Vue from 'vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import Table from '@/components/Table/Table.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import PageOptions from '@/model/page/page-options';
import Deliverer from '@/model/deliverer';
import { mapGetters } from 'vuex';
import OperationalUnit from '@/model/operational-unit';

export default Vue.extend({
  name: 'OperationalUnitDelivererAddition',
  components: {
    SearchBar,
    Table,
    LoadingIndicator,
  },
  props: {
    operationalUnit: { type: Object as () => OperationalUnit },
  },
  data() {
    return {
      loading: false,
      adding: false,
      addIcon: '/images/add.svg',
      searchText: '',
      pageOptions: new PageOptions(),
      hoveredItem: null as Deliverer | null,
      tableFields: [
        {
          text: this.$t('organizational-map.deliverers.form.name'),
          key: 'name',
          label: this.$t('organizational-map.deliverers.form.name'),
          thStyle: 'width: 85%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 15%',
        },
      ],
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters({ deliverers: 'delivererStorage/getAvailableOperationalUnitDeliverers' }),
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('delivererStorage/getAvailableDeliverersForOperationalUnit', {
          operationalUnitId: this.operationalUnit.id,
          text: this.searchText,
          pageOptions: this.pageOptions,
        })
        .finally(() => (this.loading = false));
    },
    textFiltering(searchText: string) {
      this.searchText = searchText;
      this.fetch();
    },
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
    setHover(item: Deliverer | null) {
      this.hoveredItem = item;
    },
    isHovered(item: Deliverer): boolean {
      return item?.id === this.hoveredItem?.id;
    },
    addDeliverer(deliverer: Deliverer) {
      this.adding = true;
      this.$store
        .dispatch('delivererStorage/addOperationalUnitDeliverer', {
          operationalUnitId: this.operationalUnit.id,
          deliverer,
        })
        .finally(() => (this.adding = false));
    },
  },
});
