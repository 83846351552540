


















import Vue from 'vue';
import FileInput from '@/components/InputField/FileInput.vue';
import SelectedFile from '@/components/Dashboard/RequirementAddition/SelectedFile.vue';
import Document from '@/model/document';

export default Vue.extend({
  name: 'FileUpload',
  components: {
    FileInput,
    SelectedFile,
  },
  model: {
    prop: 'uploadedFiles',
    event: 'changeAttachment',
  },
  props: {
    loading: { type: Boolean, default: false },
    uploadedFiles: { type: Array as () => File[] },
    previousDocuments: { type: Array as () => Document[] },
    isSmall: { type: Boolean, default: false },
  },
  watch: {
    uploadedFiles() {
      this.fileList = this.uploadedFiles;
    },
  },
  data() {
    return {
      fileList: this.uploadedFiles,
      documentList: this.previousDocuments,
    };
  },
  methods: {
    addAttachments(attachents: File[]) {
      this.fileList = this.fileList.concat(attachents);
      this.$emit('changeAttachment', this.fileList);
    },
  },
});
