




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import Deliverer from '@/model/deliverer';
import OperationalUnit from '@/model/operational-unit';

export default Vue.extend({
  name: 'RemoveOperationalUnitDeliverer',
  components: {
    DeleteButton,
  },
  props: {
    deliverer: { type: Object as () => Deliverer },
    operationalUnit: { type: Object as () => OperationalUnit },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    removeDeliverer() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('organizational-map.deliverers.remove', {
              name: this.deliverer.name,
              operationalUnit: this.operationalUnit.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('delivererStorage/removeOperationalUnitDeliverer', {
                operationalUniId: this.operationalUnit.id,
                delivererId: this.deliverer.id,
              })
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
